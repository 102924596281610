import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import About from "../components/About";
import Features from "../components/Features";
import Pricing from "../components/Pricing";
import Footer from "../components/Footer";
import Contact from "../components/Contact";

const Home = () => {
  const [selectedOption, setSelectedOption] = useState("Starter");

  return (
    <div className="bg-primary text-white h-[100%] font-playfair">
      <Navbar />
      <Hero />
      <About />
      <Features />
      <Pricing setSelectedOption={setSelectedOption} />
      <Contact
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      <Footer />
    </div>
  );
};

export default Home;
