import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import Whitepaper from "./routes/Whitepaper";
import Dapp from "./routes/Dapp";
import BackToHome from "./routes/BackToHome";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/whitepaper" element={<Whitepaper />} />
        <Route path="/dapp" element={<Dapp />} />
        <Route path="/*" element={<BackToHome />} />
      </Routes>
    </div>
  );
}

export default App;
