import { useState } from "react";
import { motion } from "framer-motion";
import { Link as Scroll } from "react-scroll";
import logo from "../assets/logo/linai-white-lb.webp";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigations = ["Home", "About", "Features", "Pricing", "Contact"];

  const circleVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 180,
      transition: {
        type: "spring",
        stiffness: 160,
        damping: 60,
      },
    },
  };

  const ulVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.2,
      },
    },
  };

  return (
    <nav
      className={`fixed z-10 top-0 left-0 w-full h-[10vh] transition-all duration-300 bg-tertiary bg-opacity-95 shadow-lg p-4 flex justify-center lg:justify-around items-center text-[22px] select-none`}
    >
      <Link to="/">
        <img src={logo} alt="linai - blockchain chatbots" className="h-[8vh]" />
      </Link>
      <ul className="hidden lg:flex space-x-8 capitalize">
        {navigations.map((nav, index) => (
          <li
            key={index}
            className="text-white hover:text-hover cursor-pointer"
          >
            <Scroll
              to={nav}
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
              className="transition-all duration-100"
            >
              {nav}
            </Scroll>
          </li>
        ))}
        <li>
          <Link to="/Whitepaper">Whitepaper</Link>
        </li>
        <li>
          <Link to="/Dapp">Launch DAPP</Link>
        </li>
      </ul>
      <div className="lg:hidden">
        <div
          onClick={() => setIsOpen(true)}
          className="cursor-pointer absolute top-7 right-5"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </div>
        <motion.div
          variants={circleVariants}
          initial="hidden"
          animate={isOpen ? "visible" : "hidden"}
          className="w-4 h-4 rounded-full bg-tertiary fixed top-0 right-0"
        ></motion.div>
        <motion.ul
          variants={ulVariants}
          initial="hidden"
          animate={isOpen ? "visible" : ""}
          className={`${
            isOpen ? "right-0" : "-right-full"
          } fixed top-0 bottom-0 w-full flex flex-col items-center justify-center space-y-6 transition-all duration-300 overflow-hidden`}
        >
          <div
            onClick={() => setIsOpen(false)}
            className="cursor-pointer absolute top-7 right-5"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          {navigations.map((nav, index) => (
            <li key={index}>
              <Scroll
                to={nav}
                smooth={true}
                duration={500}
                onClick={() => setIsOpen(false)}
                className="cursor-pointer"
              >
                {nav}
              </Scroll>
            </li>
          ))}
          <li>
            <Link to="/Whitepaper">Whitepaper</Link>
          </li>
          <li>
            <Link to="/Dapp">Launch DAPP</Link>
          </li>
        </motion.ul>
      </div>
    </nav>
  );
};

export default Navbar;
