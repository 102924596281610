import React from "react";
import { motion } from "framer-motion";
import navSVG from "../assets/svg/nav.svg";
import infoSVG from "../assets/svg/info.svg";
import supportSVG from "../assets/svg/support.svg";

const Features = () => {
  const features = [
    {
      image: infoSVG,
      name: "User Assistance",
      description:
        "Our AI-driven chatbot offers comprehensive user assistance within the blockchain environment. It simplifies complex concepts, provides step-by-step guidance, and answers queries related to blockchain technology, ensuring users have the necessary support to navigate seamlessly.",
    },
    {
      image: supportSVG,
      name: "Real-time Support",
      description:
        "Experience real-time support with our chatbot, providing instant solutions to your blockchain-related inquiries. Whether it's troubleshooting, market queries, or technical assistance, our chatbot delivers immediate responses, ensuring users stay updated and informed.",
    },
    {
      image: navSVG,
      name: "Platform Navigation",
      description:
        "Seamlessly navigate through the intricate landscape of blockchain platforms using our chatbot. It acts as a knowledgeable guide, assisting users in exploring, accessing, and utilizing various blockchain functionalities and services with ease.",
    },
  ];

  return (
    <section
      id="Features"
      className="w-full text-center p-10 md:p-20 bg-primary"
    >
      <h1 className="text-4xl font-bold mb-12">Key Features</h1>
      <ul className="flex flex-wrap justify-center">
        {features.map((feature, index) => (
          <motion.li
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5, delay: index / 5 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
            className="w-full md:w-1/3 px-4 my-6 md:my-0"
            key={index}
          >
            <img
              src={feature.image}
              alt={`skill ${index}`}
              className={`w-24 h-24 m-auto mb-4 animate-pulse ${
                index === 1 ? "animate-pulse-delay" : ""
              }`}
            />
            <h2 className="text-xl font-semibold mb-2">{feature.name}</h2>
            <p className="text-sm">{feature.description}</p>
          </motion.li>
        ))}
      </ul>
    </section>
  );
};

export default Features;
