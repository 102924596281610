import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <section id="About" className="w-full text-center bg-secondary p-4 md:p-20">
      <motion.h1
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="text-4xl font-bold mb-8 md:mb-12"
      >
        About Our AI-Driven Chatbots
      </motion.h1>
      <p className="text-lg mx-auto max-w-lg leading-relaxed">
        Our AI-driven chatbots are purposefully crafted to elevate the user
        experience within blockchain projects. These intelligent interfaces
        offer personalized assistance and real-time support, ensuring seamless
        navigation across blockchain platforms. Designed to simplify
        interactions, they provide invaluable aid, enhancing user engagement and
        understanding of blockchain concepts. Our chatbots are tailored to make
        blockchain technology more accessible, offering clear guidance and swift
        support whenever needed.
      </p>
    </section>
  );
};

export default About;
