import React, { useEffect, useRef, useState, useMemo } from "react";
import { Link as Scroll } from "react-scroll"; // Importing Scroll from react-scroll
import logo from "../assets/logo/linai-black-lb.webp";
import { Link } from "react-router-dom";

const Whitepaper = () => {
  const [selectedLink, setSelectedLink] = useState("Introduction");

  const navLinks = useMemo(
    () => [
      "I Introduction",
      "II Overview of Chatbot Integration in Blockchain Projects",
      "III Target Audience and Use Cases",
      "IV Technical Implementation",
      "V Integration Process and Customization Options",
      "VI User Feedback and Continuous Improvement",
      "VII Tokenomics",
    ],
    []
  );

  const sectionRefs = useRef([]);
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = sectionRefs.current.findIndex(
              (ref) => ref === entry.target
            );
            setSelectedLink(navLinks[index]);
          }
        });
      },
      { threshold: 0.5 }
    );

    sectionRefs.current = sectionRefs.current.slice(0, navLinks.length);

    sectionRefs.current.forEach((ref) => {
      observer.current.observe(ref);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [navLinks]);

  return (
    <div className="flex bg-gray-100 min-h-screen">
      <div className="hidden md:block w-[300px] bg-white shadow-lg">
        <div className="sticky top-0 p-6">
          <h3 className="text-2xl font-semibold mb-4">Table of Contents</h3>
          <ul>
            {navLinks.map((link, index) => (
              <li className="mb-3" key={index}>
                <Scroll
                  to={link.toLowerCase().replace(/\s/g, "-")}
                  spy={true}
                  smooth={true}
                  duration={500}
                  className={`block text-lg ${
                    link.toLowerCase().replace(/\s/g, "-") ===
                    selectedLink.toLowerCase().replace(/\s/g, "-")
                      ? "text-blue-700 underline"
                      : "text-gray-700"
                  } hover:text-blue-500 transition duration-300 cursor-pointer`}
                >
                  {link}
                </Scroll>
              </li>
            ))}
            <li>
              <Link
                to="/"
                className="mt-auto hover:text-blue-500 transition duration-300"
              >
                Home
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="md:w-3/4 md:p-4 md:pt-0">
        <div className="bg-white md:bg-gray-100 sticky top-0 w-full">
          <Link to="/">
            <img src={logo} alt="" className="p-3 mx-auto h-[10vh]" />
          </Link>
        </div>

        <section
          ref={(ref) => (sectionRefs.current[0] = ref)}
          id={navLinks[0].toLowerCase().replace(/\s/g, "-")}
          className="mb-8 p-10 md:p-20 bg-white shadow-md rounded-lg"
        >
          <h2 className="text-2xl font-bold mb-4">{navLinks[0]}</h2>
          <p className="text-lg leading-relaxed">
            The rapid evolution of blockchain technology has ushered in
            transformative changes across industries, promising decentralized
            solutions, heightened transparency, and robust security measures.
            Yet, amid this paradigm shift, a pressing challenge persists—the
            demand for a seamless and user-friendly experience.
            <br />
            <br />
            Blockchain projects, with their intricate ecosystems and technical
            complexities, often present a daunting learning curve for users.
            Navigating these platforms, comprehending their functionalities, and
            engaging with their features can be formidable barriers to
            widespread adoption.
            <br />
            <br />
            At the heart of this challenge lies the imperative to bridge the gap
            between cutting-edge technology and user accessibility. As
            blockchain projects endeavor to attract and retain users, the need
            for an intuitive, user-friendly interface becomes increasingly
            vital.
            <br />
            <br />
            This whitepaper introduces a game-changing solution—AI-driven
            chatbot integration within blockchain projects. These intelligent
            conversational interfaces are meticulously designed to transform
            user experiences by simplifying navigation, delivering instantaneous
            assistance, and providing comprehensive insights into project
            functionalities.
            <br />
            <br />
            By harnessing the power of artificial intelligence, these chatbots
            democratize access to blockchain technology, transcending barriers,
            and empowering users with a highly intuitive means of interaction.
            The integration of AI-driven chatbots represents a pivotal leap
            towards enhancing user engagement, simplifying complexities, and
            fostering broader adoption within the blockchain ecosystem.
            <br />
            <br />
            In the subsequent sections, we delve into the profound impact and
            intricacies of integrating AI-driven chatbots within blockchain
            projects, elucidating their pivotal role in driving user-centric
            experiences and propelling the industry towards a more accessible
            and inclusive future.
          </p>
        </section>
        <section
          ref={(ref) => (sectionRefs.current[1] = ref)}
          id={navLinks[1].toLowerCase().replace(/\s/g, "-")}
          className="mb-8 p-10 md:p-20 bg-white shadow-md rounded-lg"
        >
          <h2 className="text-2xl font-bold mb-4">{navLinks[1]}</h2>
          <div className="text-lg leading-relaxed">
            <div className="mb-6">
              <strong>Explanation of Chatbots:</strong>
              <p>
                Chatbots, powered by artificial intelligence (AI), represent
                intelligent conversational interfaces engineered to simulate
                human-like interactions. Leveraging sophisticated natural
                language processing (NLP) and machine learning algorithms, these
                chatbots adeptly comprehend user queries, discern intent, and
                furnish pertinent responses or actions. In the context of
                blockchain projects, these AI-driven chatbots operate as virtual
                assistants, adeptly guiding users, addressing queries, and
                simplifying navigation through intricate platforms.
              </p>
            </div>
            <div className="mb-6">
              <strong>Relevance in Blockchain Projects:</strong>
              <p>
                Within the intricate ecosystem of blockchain projects,
                characterized by technical complexities that challenge user
                accessibility, the integration of AI-driven chatbots becomes
                pivotal. These chatbots function as user-friendly interfaces,
                bridging the divide between users and the complexities inherent
                in blockchain technology. By facilitating intuitive
                conversational interactions, they empower users to effortlessly
                navigate various features, access critical information, and
                seamlessly engage with the platform.
              </p>
            </div>
            <div className="mb-6">
              <strong>Benefits of Chatbots:</strong>
              <ul className="list-disc ml-8">
                <li>
                  Enhanced User Engagement: Chatbots facilitate real-time
                  interactions, fostering personalized experiences that elevate
                  user satisfaction and retention levels.
                </li>
                <li>
                  Streamlined Onboarding: Providing instant guidance and
                  information, chatbots expedite the onboarding process for new
                  users, diminishing the learning curve and enabling quicker
                  platform familiarity.
                </li>
                <li>
                  Efficient Support Mechanism: These AI-driven assistants offer
                  uninterrupted support, promptly addressing user queries and
                  ensuring consistent assistance round-the-clock, thereby
                  significantly bolstering user satisfaction and support
                  efficiency.
                </li>
              </ul>
            </div>
            <div className="mb-6">
              <strong>Illustrative examples or case studies:</strong>
              <p>
                Illustrative examples or case studies showcasing how AI-driven
                chatbots enhance user experiences and streamline interactions
                within blockchain projects could further reinforce the benefits
                mentioned above.
              </p>
            </div>
          </div>
        </section>
        <section
          ref={(ref) => (sectionRefs.current[2] = ref)}
          id={navLinks[2].toLowerCase().replace(/\s/g, "-")}
          className="mb-8 p-10 md:p-20 bg-white shadow-md rounded-lg"
        >
          <h2 className="text-2xl font-bold mb-4">{navLinks[2]}</h2>
          <div className="text-lg leading-relaxed">
            <div className="mb-6">
              <h3 className="font-bold">Target Audience:</h3>
              <p>
                The integration of AI-driven chatbot services within diverse
                blockchain projects caters to a broad spectrum of clients,
                primarily various blockchain platforms. These platforms seek to
                enrich their users' experiences by integrating AI-driven
                chatbots, ensuring seamless, informative, and interactive
                interactions.
              </p>
            </div>
            <div className="mb-6">
              <h3 className="font-bold">Use Cases for the End Users:</h3>
              <p>
                Highlighting the adaptable nature of AI-driven chatbots within
                different blockchain projects, let's delve into how these
                chatbots can specifically benefit the end users of each project:
              </p>
              <ul className="list-disc ml-8">
                <li>
                  Personalized Assistance: Tailored guidance and insights into
                  the respective blockchain project's missions, tokenomics,
                  utility, and features, aligning with individual user queries
                  and interests.
                </li>
                <li>
                  Real-time Support: Addressing project-specific FAQs promptly,
                  providing immediate clarification on functionalities, security
                  measures, and project-specific roadmap inquiries.
                </li>
                <li>
                  Platform Navigation: Assisting users in navigating through the
                  distinct features of the particular blockchain project,
                  accessing wallets, and simplifying project-specific
                  functionalities such as staking, yield farming, or unique
                  protocols.
                </li>
                <li>
                  Technical Support: Offering project-specific technical
                  assistance to users encountering platform-related issues.
                </li>
              </ul>
              <p>
                By tailoring chatbot interactions to these specific use cases
                and potentially incorporating real-world examples aligned with
                each scenario, AI-driven assistants significantly enhance user
                experiences within distinct blockchain projects. This approach
                fosters improved accessibility, knowledge dissemination, and
                proficient support.
              </p>
            </div>
            <div className="mb-6">
              <h3 className="font-bold">Outcome:</h3>
              <p>
                By customizing chatbot functionalities to cater specifically to
                the diverse needs and intricacies of each blockchain project,
                these AI-driven assistants significantly enhance user
                experiences. This tailored approach ensures that users
                seamlessly engage with the platform, fostering increased
                accessibility, knowledge dissemination, and proficient support.
                Ultimately, this proactive user engagement leads to heightened
                user satisfaction, improved retention rates, and a thriving
                ecosystem within each individual blockchain project.
              </p>
            </div>
          </div>
        </section>
        <section
          ref={(ref) => (sectionRefs.current[3] = ref)}
          id={navLinks[3].toLowerCase().replace(/\s/g, "-")}
          className="mb-8 p-10 md:p-20 bg-white shadow-md rounded-lg"
        >
          <h2 className="text-2xl font-bold mb-4">{navLinks[3]}</h2>
          <div className="text-lg leading-relaxed">
            <div className="mb-6">
              <h3 className="font-bold">
                Chatbot Development and Integration:
              </h3>
              <p>
                Upon completion of the chatbot's development phase, the next
                step involves the seamless integration of the customized chatbot
                into users' websites. This integration process is
                straightforward and involves:
              </p>
            </div>
            <ul className="list-disc ml-8 mb-6">
              <li>
                <strong>Chatbot Development:</strong> The AI-driven chatbot is
                meticulously developed, fine-tuned, and thoroughly tested to
                align with the predetermined specifications established during
                consultations.
              </li>
              <li>
                <strong>Chatbot Publication:</strong> Once the chatbot
                development is finalized, it undergoes publication, ensuring it
                is optimized, functional, and ready for deployment.
              </li>
            </ul>
            <div className="mb-6">
              <h3 className="font-bold">Embedding Process:</h3>
              <p>
                To enable effortless integration into users' websites, a
                JavaScript script tag containing pre-configured data is
                provided:
              </p>
            </div>
            <ul className="list-disc ml-8 mb-6">
              <li>
                <strong>JavaScript Script Tag:</strong> Users are furnished with
                a JavaScript script tag. This script includes essential
                pre-configured data, such as the project ID, URL references,
                version ID, and necessary source parameters.
              </li>
            </ul>
            <div className="mb-6">
              <h3 className="font-bold">Chatbot Integration:</h3>
              <p>
                Embedding the chatbot into a website's source code is a swift
                and simple process:
              </p>
            </div>
            <ul className="list-disc ml-8 mb-6">
              <li>
                <strong>Embedding the Script:</strong> Users effortlessly
                incorporate the provided JavaScript script tag into their
                website's source code. This action initiates the seamless
                integration of the customized chatbot into the website.
              </li>
            </ul>
            <p>
              Upon successful embedding, the chatbot becomes fully operational,
              delivering user assistance and engagement as per the predefined
              specifications.
            </p>
          </div>
        </section>
        <section
          ref={(ref) => (sectionRefs.current[4] = ref)}
          id={navLinks[4].toLowerCase().replace(/\s/g, "-")}
          className="mb-8 p-10 md:p-20 bg-white shadow-md rounded-lg"
        >
          <h2 className="text-2xl font-bold mb-4">{navLinks[4]}</h2>
          <div className="text-lg leading-relaxed">
            <div className="mb-6">
              <h3 className="font-bold">Integration Process:</h3>
              <p>
                The seamless integration of AI-driven chatbots into diverse
                blockchain projects involves a systematic process tailored to
                meet specific project requirements:
              </p>
            </div>
            <ul className="list-disc ml-8 mb-6">
              <li>
                <strong>Initial Consultation:</strong> Commences with an
                in-depth discussion to understand the unique needs and
                functionalities desired by the blockchain project.
              </li>
              <li>
                <strong>Development Phase:</strong> Meticulous development,
                testing, and fine-tuning of the chatbot to align with
                predetermined specifications established during consultations.
              </li>
              <li>
                <strong>Publication and Optimization:</strong> Finalization of
                the chatbot's development involves optimization, ensuring it
                meets functionality and deployment standards.
              </li>
            </ul>
            <div className="mb-6">
              <h3 className="font-bold">Customization Options:</h3>
              <p>
                The versatility of AI-driven chatbots allows for tailored
                customization aligned with individual project requirements:
              </p>
            </div>
            <ul className="list-disc ml-8 mb-6">
              <li>
                <strong>Functional Customization:</strong> Options to customize
                the chatbot's functionalities, including user interaction
                pathways, information delivery, and specific user queries
                handling.
              </li>
              <li>
                <strong>Design and Appearance:</strong> Flexible customization
                options regarding the chatbot's appearance, branding elements,
                and user interface for seamless integration with the project's
                website or platform.
              </li>
              <li>
                <strong>User Experience Optimization:</strong> Customization
                tools and features that enhance user experiences by aligning the
                chatbot's responses and interactions with the project's
                objectives and user expectations.
              </li>
            </ul>
          </div>
        </section>
        <section
          ref={(ref) => (sectionRefs.current[5] = ref)}
          id={navLinks[5].toLowerCase().replace(/\s/g, "-")}
          className="mb-8 p-10 md:p-20 bg-white shadow-md rounded-lg"
        >
          <h2 className="text-2xl font-bold mb-4">{navLinks[5]}</h2>
          <div className="text-lg leading-relaxed">
            <div className="mb-6">
              <h3 className="text-lg font-bold">Gathering User Feedback:</h3>
              <p>
                Collecting user feedback is integral to understanding user
                experiences and improving the chatbot's functionalities within
                blockchain projects:
              </p>
            </div>
            <ul className="list-disc ml-8 mb-6">
              <li>
                <strong>Feedback Channels:</strong> Implementation of various
                feedback channels such as surveys, user ratings, or direct user
                interactions to gather insights.
              </li>
              <li>
                <strong>Analyzing User Responses:</strong> Systematic analysis
                of user feedback to identify trends, pain points, and areas for
                improvement.
              </li>
              <li>
                <strong>Iterative Refinement:</strong> Utilization of collected
                feedback for iterative enhancements, ensuring the chatbot aligns
                with evolving user needs.
              </li>
            </ul>
            <div className="mb-6">
              <h3 className="text-lg font-bold">
                Continuous Improvement Strategies:
              </h3>
              <p>
                Strategies employed to continuously refine and enhance the
                chatbot's performance and user experience:
              </p>
            </div>
            <ul className="list-disc ml-8 mb-6">
              <li>
                <strong>Agile Development Approach:</strong> Embracing an agile
                methodology to adapt to user feedback promptly and implement
                iterative improvements.
              </li>
              <li>
                <strong>AI Learning and Adaptation:</strong> Leveraging machine
                learning algorithms to enable the chatbot to learn from
                interactions and autonomously improve responses over time.
              </li>
              <li>
                <strong>Regular Updates and Enhancements:</strong> Scheduled
                updates and feature enhancements based on user feedback and
                emerging technological advancements.
              </li>
            </ul>
            <div className="mb-6">
              <h3 className="text-lg font-bold">Implementation and Results:</h3>
              <p>
                Details on how user feedback is translated into actionable
                improvements and the resultant enhancements in the chatbot's
                performance within blockchain projects.
              </p>
              <p>
                This section emphasizes the significance of user feedback in
                driving continuous improvements, outlining strategies for
                implementing feedback effectively, and showcasing the tangible
                benefits resulting from this iterative process.
              </p>
            </div>
          </div>
        </section>
        <section
          ref={(ref) => (sectionRefs.current[6] = ref)}
          id={navLinks[6].toLowerCase().replace(/\s/g, "-")}
          className="mb-8 p-10 md:p-20 bg-white shadow-md rounded-lg"
        >
          <h2 className="text-2xl font-bold mb-4">{navLinks[6]}</h2>
          <div className="text-lg leading-relaxed">
            <div className="mb-6">
              <h3 className="text-lg font-bold">
                Introduction to "Lin" Token Utility:
              </h3>
              <p>
                Detailing the central role of the "Lin" token as the access key
                for utilizing the chatbot services on the platform:
              </p>
            </div>
            <ul className="list-disc ml-8 mb-6">
              <li>
                Access Token: The "Lin" token functions as the access pass to
                initiate interactions within the chatbot environment on our
                platform.
              </li>
              <li>
                Usage Mechanism: Users connect their wallets (e.g., MetaMask)
                and require a positive "Lin" token balance to access the chatbot
                services.
              </li>
            </ul>
            <div className="mb-6">
              <h3 className="text-lg font-bold">
                Token Utilization for Chatbot Interaction:
              </h3>
              <p>
                Explaining how the "Lin" token is utilized during interactions
                within the chatbot environment:
              </p>
            </div>
            <ul className="list-disc ml-8 mb-6">
              <li>
                Chat Interaction: Users initiate queries or actions within the
                chatroom by leveraging the "Lin" token, which is utilized for
                each request and response cycle.
              </li>
              <li>
                Token Cost Structure: The token's usage incurs a specific charge
                per request or response, enabling seamless chatbot interactions
                upon adequate token balance.
              </li>
            </ul>
            <div className="mb-6">
              <h3 className="text-lg font-bold">
                Acquiring and Maintaining "Lin" Token Balance:
              </h3>
              <p>
                Guidelines for users to acquire and manage their "Lin" token
                balance for continued chatbot access:
              </p>
            </div>
            <ul className="list-disc ml-8 mb-6">
              <li>
                Token Acquisition: Users can obtain "Lin" tokens through
                supported exchanges or platforms to maintain a sufficient
                balance for chatbot interactions.
              </li>
              <li>
                Balance Maintenance: It is essential to maintain a positive
                token balance in their connected wallet to access chatbot
                functionalities continually.
              </li>
            </ul>
            <div className="mb-6">
              <h3 className="text-lg font-bold">Token Burning Mechanism:</h3>
              <p>Detailing the intentional reduction of the token supply:</p>
            </div>
            <ul className="list-disc ml-8 mb-4">
              <li>
                Upon utilizing the chatbot services with the "Lin" token, a
                predetermined amount of tokens is deducted from the user's
                wallet for each interaction. After each interaction, the spent
                tokens are systematically transferred to 0x address.
              </li>
              <li>
                The 0x address is a non-spendable address on the blockchain
                network, rendering any tokens sent there permanently
                inaccessible and effectively eliminating them from circulation.
                The tokens directed to the 0x address are effectively "burned,"
                resulting in a direct reduction of the total token supply.
              </li>
              <li>
                This intentional reduction in the token supply aims to create
                scarcity, potentially influencing the token's value by limiting
                its availability.
              </li>
            </ul>
            <p>
              The token burn process is immutable and transparent, recorded on
              the blockchain, allowing for verification and assurance regarding
              the irreversible nature of token removal from circulation.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Whitepaper;
