import React from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import image from "../assets/ai-contact-65969a25c92e6.webp";
import { useRef } from "react";

const Contact = ({ selectedOption, setSelectedOption }) => {
  const form = useRef();

  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await emailjs.sendForm(
        "service_f7nwuxe",
        "template_ahu1gq7",
        form.current,
        "qro0gTH5HPg5UX2_F"
      );
      console.log(result.text);
      form.current.reset();
      alert("Message Sent!");
    } catch (error) {
      console.error(error.text);
    }
  };

  return (
    <section id="Contact" className="p-10 md:p-20">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="text-center"
      >
        <p className="text-4xl">Contact Us</p>
      </motion.div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between items-center mt-14">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="basis-1/2 mt-10 md:mt-0"
        >
          <form ref={form} onSubmit={onSubmit}>
            <label className="block mt-4">
              <span className="text-lg font-semibold">Email:</span>
              <input
                className="w-full rounded-lg text-black font-semibold placeholder-opaque-black p-3 mt-2 border border-gray-300 focus:outline-none focus:border-blue-500"
                type="email"
                name="email_from"
                placeholder="Your Email"
                required
              />
            </label>

            <label className="block mt-4">
              <span className="text-lg font-semibold">Website:</span>
              <input
                className="w-full rounded-lg text-black font-semibold placeholder-opaque-black p-3 mt-2 border border-gray-300 focus:outline-none focus:border-blue-500"
                type="text"
                name="website"
                placeholder="Your Project's Website"
                required
              />
            </label>

            <label className="block mt-4">
              <span className="text-lg font-semibold">Plan:</span>
              <select
                className="w-full rounded-lg text-black font-semibold placeholder-opaque-black p-3 mt-2 border border-gray-300 focus:outline-none focus:border-blue-500"
                value={selectedOption}
                onChange={handleDropdownChange}
                name="plan"
              >
                <option value="Starter">Starter</option>
                <option value="Pro">Pro</option>
                <option value="Custom">Custom</option>
                <option value="NA">N/A</option>
              </select>
            </label>

            <label className="block mt-4">
              <span className="text-lg font-semibold">Message:</span>
              <textarea
                className="w-full rounded-lg text-black font-semibold placeholder-opaque-black p-3 mt-2 border border-gray-300 focus:outline-none focus:border-blue-500"
                placeholder="Your Message"
                rows={4}
                cols={50}
                name="message"
              />
            </label>

            <button className="animate-bounce flex rounded-md text-lg bg-button mt-12 md:bg-opacity-70 hover:bg-opacity-100 md:w-1/2 justify-center mx-auto px-5 py-2">
              Contact us
            </button>
          </form>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="md:w-1/3 flex justify-center"
        >
          <img src={image} alt="contact-us" className="w-full h-full" />
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;
