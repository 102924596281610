import React from "react";
import { motion } from "framer-motion";
import { Link as Scroll } from "react-scroll";

const plans = [
  {
    name: "Starter",
    price: "$19.99/month",
    details: [
      "24/7 Support",
      "Basic hosting",
      "Regular updates",
      "Basic maintenance",
      "Advanced AI models",
    ],
  },
  {
    name: "Pro",
    price: "$39.99/month",
    details: [
      "24/7 Support",
      "Enhanced hosting",
      "Frequent updates",
      "Regular maintenance",
      "Latest AI models",
    ],
  },
  {
    name: "Custom",
    price: " - ",
    details: [
      "Custom hosting solutions",
      "Frequent updates",
      "High-level scalability",
      "Priority maintenance",
      "Comprehensive statistics",
    ],
  },
];

const Pricing = ({ setSelectedOption }) => {
  return (
    <section
      id="Pricing"
      className="w-full text-center p-10 md:p-20 bg-secondary"
    >
      <motion.h1
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        className="text-4xl font-bold mb-12"
      >
        Pricing
      </motion.h1>

      {/* Development Price */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 1 }}
        className="text-lg my-8 mx-auto max-w-lg"
      >
        <h2 className="text-xl font-semibold">Development Price:</h2>
        <p className="mt-2">
          The development price for creating an AI chatbot for your blockchain
          project typically ranges from $500 up to $10,000+. This range depends
          on various factors including the complexity, features, integration,
          and customization required.
        </p>
      </motion.div>

      {/* Monthly Plans */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 1 }}
        className="text-lg my-8 mx-auto max-w-lg"
      >
        <h2 className="text-xl font-semibold mt-10">Monthly Plans:</h2>
        <p className="mt-2">
          Choose the plan that best suits your requirements and budget:
        </p>
      </motion.div>

      {/* Plan Cards */}
      <div className="flex flex-wrap justify-center items-start mt-4">
        {plans.map((plan, index) => (
          <motion.div
            key={index}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5, delay: index / 5 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
            className="w-full sm:w-1/2 md:w-2/5 lg:w-1/4 p-4"
          >
            <div className="flex flex-col justify-between bg-primary rounded-lg shadow-lg p-6 px-10 h-[400px]">
              <h2 className="text-lg font-semibold">{plan.name}</h2>
              <p className="text-2xl font-bold mt-2">{plan.price}</p>
              <ul className="list-disc text-left mt-4 h-full">
                {plan.details.map((detail, index) => (
                  <li key={index} className="mb-2">
                    {detail}
                  </li>
                ))}
              </ul>
              <Scroll
                to="Contact"
                smooth={true}
                duration={500}
                onClick={(e) => setSelectedOption(plan.name)}
                className="cursor-pointer flex rounded-md text-lg bg-button md:bg-opacity-70 hover:bg-opacity-100  justify-center mx-auto px-5 py-2"
              >
                <p>Choose Plan</p>
              </Scroll>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Pricing;
