import React from "react";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import img from "../assets/ai-hero-65969a26a0758.webp";

const Hero = () => {
  return (
    <section
      id="Home"
      className="flex flex-col-reverse md:flex-row justify-around items-center p-5 md:p-20 mt-[10vh] lg:h-[90vh]"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="w-[63vw] md:w-[50vw] lg:w-[30vw] mr-auto space-y-8 md:left-20 bg-black bg-opacity-40 md:bg-opacity-85"
      >
        <h1 className="text-xl md:text-4xl">
          AI-Driven Chatbots{" "}
          <span className="hidden md:block">
            Revolutionizing Blockchain Interaction
          </span>
        </h1>
        <p>
          The future of blockchain interaction is here. Explore our innovative
          AI-driven chatbot solutions tailored for the blockchain industry.
          <span className="hidden md:block">
            Simplify user engagement, enhance accessibility, and revolutionize
            your project's interactions.
          </span>
        </p>
        <div>
          <Link
            to="Contact"
            spy={true}
            smooth={true}
            duration={500}
            offset={-70}
            className="transition-all duration-100"
          >
            <button className="animate-bounce flex rounded-md text-lg bg-button mt-12 md:bg-opacity-70 hover:bg-opacity-100 md:w-1/2 justify-center mx-auto px-5 py-2">
              Contact us
            </button>
          </Link>
        </div>
      </motion.div>
    </section>
  );
};

export default Hero;
