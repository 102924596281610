import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-6">
      <div className="container mx-auto text-center">
        <p className="text-sm">
          &copy; {new Date().getFullYear()} LinAI Blockchain Chatbots. All
          Rights Reserved.
        </p>
        <div className="mt-4 flex flex-col md:flex-row items-center justify-center">
          <div className="flex items-center mb-4 md:mb-0">
            <span className="text-gray-500 mx-2">|</span>
            <p className="text-gray-300 hover:text-white mx-2">Contact Us</p>
          </div>
          <div className="flex items-center">
            <span className="text-gray-400">Follow Us:</span>
            <div className="ml-2">
              <p className="text-gray-300 hover:text-white mx-2">
                <i className="fab fa-github">Github</i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
