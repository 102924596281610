import React from "react";

const Dapp = () => {
  return (
    <div className="bg-primary text-white font-playfair h-[100vh] text-center">
      <p className="my-auto text-4xl"> In Development</p>
      <a href="/">Back</a>
    </div>
  );
};

export default Dapp;
